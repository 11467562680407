.wifi-symbol {
  display: none;

  $circleColor: black;
  $size: 19px;

  & {
    position: relative;
    display: inline-block;
    width: $size;
    height: $size;

    transform: rotate(-45deg) translate(0px);
  }

  .wifi-circle {
    box-sizing: border-box;
    display: block;
    width: 100%;
    height: 100%;
    font-size: calc($size/7);
    position: absolute;
    bottom: 0;
    left: 0;
    border-color: $circleColor;
    border-style: solid;
    border-width: 1em 1em 0 0;
    -webkit-border-radius: 0 100% 0 0;
    border-radius: 0 100% 0 0;

    opacity: 1;

    &.second {
      width: 5em;
      height: 5em;
    }

    &.third {
      width: 3em;
      height: 3em;
    }

    &.fourth {
      width: 1em;
      height: 1em;
      opacity: 1;
    }
  }
}
.battery-container{
  padding: 0px 8px;
  border: 3px rgb(36, 36, 36) solid;
  border-radius: 4px;
  width:20px ;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 2px;
  rotate: 90deg;
  
 }
 .battery-cell{
   width: 13px;
   height: 4px;
   background-color: green;
   margin-top: 2px;
   border-radius: 1px;
 }
 .battery-top{
   position: absolute;
   width: 10px;
   height: 5px;
   background-color: black;
   border-radius: 3px 3px 0 0 ;
   top: -5px;
   left: 50%;
   transform: translateX(-50%);
 }