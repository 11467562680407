.map-container {
    position: relative;

}

.parrent-pin {
    position: absolute;
    inset: 0;
    background-color: transparent;
}

.map-image {
    width: 100%;
    height: auto;
    display: block;
}

.pin {
    font-size: 5%;
    color: red;
    cursor: pointer;

}

.map-description {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
      0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    border-radius: 6px;
}
.map-content{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.marker-content {
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
      0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    border-radius: 6px;
  }
  .pinName{
    &:hover{
        color: lightgray;
    }
  }