@import "./styles/css/desktop.scss";
@import "./styles/css/mobile.scss";
@import "./styles/css/desktop-button.scss";
@import "./styles/css/vars.scss";

$gray: #f3f3f3;

.ant-layout {
  // background-color: #f4f6fd !important;
}

.ant-scrolling-effect {
  width: 100% !important;
}

.ant-dropdown {
  z-index: 10001 !important;
}

.Headers,
.NavBar {
  height: 40px;
}

.Headers {
  padding: 3px;
}

.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before,
.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::after {
  display: inline-block;
  margin-right: 5px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}

.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  content: "";
}

.border-normal {
  border: 1px solid $color-border;
}

select {
  border-color: $color-border;
  border-radius: $borderRadius;
}

/* --------------------Desktop-------------------- */

.title-page {
  display: none;
  padding: 16px;

  h1 {
    color: #000;
    font-size: 18px;
    font-weight: 600 !important;
    margin-bottom: 0 !important;
  }
}

.title-form-item {
  font-size: 14px;
  font-weight: bold;

  &.custom-title {
    background-color: #f2f2f2ba;
    border-left: 1px solid #0000000f;
  }

  &-1 {
    border-left: 1px solid #0000000f;
  }
}

.content-form-item {
  border-right: 1px solid #0000000f;
}

.loading-icon {
  position: fixed;
  top: 40%;
  left: 40%;
}

/* Group */

/* Title group */
$laptop-size-L: 1400px;
$laptop-size-M: 1200px;
$laptop-size-S: 1000px;

// @media (max-width: $laptop-size-S) {
//   .header {
//     padding: 5px 0 5px 0!important;
//   }
//   .des-navbar {
//     padding: 9px 13px!important;
//   }
//   .des-content {
//     width: 98%;
//     margin: auto;
//   }
// }

// @media (min-width: $laptop-size-S) {
//   .des-content {
//     width: 95%;
//     margin: auto;
//   }
// }

// @media (min-width: $laptop-size-M) {
//   .des-content {
//     width: 90%;
//     margin: auto;
//   }
// }

// @media (min-width: $laptop-size-L) {
//   .des-content {
//     width: 1300px;
//     margin: auto;
//   }
// }

.title-group h3 {
  border-bottom: 1px solid #f49a23;
  padding-left: 10px;
  margin-bottom: 5px;
  font-size: 120%;
  font-weight: bold;
}

.sub-title-group {
  padding-left: 10px;
  font-size: 12px;
  font-weight: bold;
}

// @media (max-width: 1300px) {
//   .sub-title-group {
//     font-size: 12px;
//   }
// }

/* Body Group */
/* Item */
label.row-input-label {
  font-weight: bold;
}

.mark-required {
  color: #ff4d4f;
}

.row-input {
  /* width: 650px;
  margin: auto; */
  margin: 10px auto;

  .row-input-col-first {
    margin: 7px 0px 0;
  }
}

.row-input-box {
  margin: 0 auto;

  .row-input-col-first {
    padding: 14px 10px;
    border-left: 1px solid $color-border-2;
    background: #f2f2f2ba;
  }
  .row-input-col-2 {
    padding: 14px 10px;
    border-right: 1px solid $color-border-2;
    word-break: break-all;
    white-space: pre-line;
  }
}

.row-input-box.mobile {
  margin: 0 auto;
  padding-top: 10px;

  .row-input-col-first {
    padding: 5px 10px;
    border: 0px;
    background: #ffffff;
  }

  .row-input-col-2 {
    padding: 5px 10px;
    border: 0px;
    word-break: break-all;
    white-space: pre-line;
  }
}

.break-line {
  height: 1px;
  width: 100%;
  background-color: rgb(230, 230, 230);
}

.input-required,
.tag-label {
  height: 24px;
  border: 2px solid #fff;
  border-radius: 5px;
  padding: 4px 6px;
  font-size: 12px;
  line-height: 13px;
  margin: 0px 13px 0 0;
}

.tag-label {
  font-size: 14px;
}

.input-required {
  background-color: #ff6600 !important;
}

.ant-divider-vertical {
  border-color: $color-border;
}

// select
select {
  cursor: pointer;
}

.selectHtml {
  border: 1px solid $color-border;
  height: 32px;
  border-radius: $borderRadius;
  color: #2b2b2b;
  padding: 0 7px;
  text-overflow: ellipsis;

  &-M {
    height: 32px;
    font-size: 16px;
  }
}

.selectM {
  height: 35px;
  font-size: 15px;
}

/* Select tree */
.box-select {
  height: 242px;
  margin: 10px 0;
  padding-left: 15px;
  padding-right: 15px;
  border: 1px solid $color-border;
  border-radius: $borderRadius;
}

.box-select-row {
  height: 441px;
  overflow: auto;
  margin: 0;
  padding-left: 5px;
  padding-right: 15px;
  /* border: 1px solid #d9d9d9; */
  border-radius: $borderRadius;
}

.box-select-title {
  margin: -10px 0 0 0;
  background: white;
  font-weight: bold;
  width: max-content;
}

.ant-tree {
  background: transparent !important;
}

.box-select-note {
  position: absolute;
  margin-top: -55px;
  margin-left: -27px;
}

/* Style */
.center {
  text-align: center;
}

.right {
  text-align: right;
}

.left {
  text-align: left;
}

/* Color */
.orange {
  color: #ff6600;
}

.black {
  color: #000000d9;
}

.blue {
  color: $main_color !important;
}

.gray {
  color: #eeeeee;
}

/* Display */
.d-none {
  display: none;
}

.d-flex {
  display: flex;
}

.d-block {
  display: block !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-inline-flex {
  display: inline-flex;
}

/* Icon */
.iconL {
  font-size: 32px;
}

.iconM {
  font-size: 25px;
}

.iconS {
  font-size: 17px;
}

// /* Button */
.ant-picker,
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: $borderRadius !important;
}

.ant-btn,
.ant-select-single:not(.ant-select-customize-input) {
  height: 35px !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 33px;
}

.ant-select-arrow {
  top: 52%;
}

.ant-select {
  font-size: $main_size;
}

// Blue Drak
.btnBlueDrak {
  background: linear-gradient(to bottom, #08c, #04c) !important;
  color: #fff !important;
}

.btnBlueDrak:hover {
  color: #fff !important;
  background: #04c !important;
}

/* add  */
.btnAdd,
.btnBlue {
  background: linear-gradient(to bottom, #5bc0de, #2f96b4) !important;
  color: #fff !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25) !important;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25) !important;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
    0 1px 2px rgba(0, 0, 0, 0.05) !important;
  border-width: 1px !important;
}

.btnAdd:hover,
.btnBlue:hover {
  background: linear-gradient(to top, #5bc0de, #2f96b4) !important;
  color: #fff !important;
}

.btnAdd:disabled,
.btnBlue:disabled {
  background: #93c3e5 !important;
  color: #fff !important;
}

/* draft */

.btnError {
  background-color: #ee5f5b !important;
  background-image: linear-gradient(to bottom, #ee5f5b, #bd362f) !important;
  color: #fff !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25) !important;
  background-repeat: repeat-x;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
}

.btnError:hover {
  background-color: #bd362f !important;
  background-image: none !important;
  color: #fff !important;
}

.btnDraft {
  background-color: #61bc48 !important;
  background-image: linear-gradient(to bottom, #61bc48, #4e9c39) !important;
  color: #fff !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25) !important;
  background-repeat: repeat-x;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
}

.btnDraft:hover {
  background-color: #61bc48 !important;
  background-image: none !important;
  color: #fff !important;
}

/* save */
.btnSave {
  // background: linear-gradient(to bottom, #444, #222) !important;
  background: linear-gradient(to bottom, #5080c6, #3c5c8a) !important;
  color: #fff !important;
  border: 1px solid #40669c;
}

.btnSave:hover {
  // background: linear-gradient(to top, #444, #222) !important;
  background: linear-gradient(to bottom, #5587d1, #456ea7) !important;
  color: #fff !important;
}

.btnSave:disabled {
  // background: #93c3e5 !important;
  background: #6a9e73 !important;
  color: #fff !important;
}

// warning
.btnWarning {
  background-color: #faa732 !important;
  background-image: linear-gradient(to bottom, #fbb450, #f89406) !important;
  color: #fff !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25) !important;
  background-repeat: repeat-x;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
}

.btnWarning:hover {
  background-color: #f89406 !important;
  background-image: none !important;
  color: #fff !important;
}

// Success
.btnSuccess {
  background-repeat: repeat-x;
  color: #fff !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25) !important;
  background-color: #5bb75b !important;
  background-image: linear-gradient(to bottom, #62c462, #51a351);
}



.btnSuccess,
.btnWarning,
.btnError {
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25) !important;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
    0 1px 2px rgba(0, 0, 0, 0.05) !important;
  border-width: 1px !important;
}
.btnSuccess:hover {
  background-color: #51a351 !important;
  text-decoration: none;
  background-position: 0 -15px;
}

//
.btnIcon {
  background-size: 40px;
  background-color: #e6e6e6 !important;
  color: #333 !important;
  background-position-x: 5px;

  padding: 5px 20px !important;
  padding-left: 45px !important;
  border-radius: 4px !important;
  height: 40px !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25) !important;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25) !important;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
    0 1px 2px rgba(0, 0, 0, 0.05) !important;
  border-width: 1px !important;
  background-repeat: no-repeat !important;
  margin-bottom: 10px;
}

.btnBlue {
  min-width: 6em;
  height: 40px !important;
  font-size: 18px !important;
  font-weight: 500 !important;
}

/* Cancel */

.btnSearch {
  min-width: 6em;

  border-radius: 3px;
  background-size: 25px;
  background-color: #e6e6e6 !important;
  color: #333 !important;
  background-position-x: 7px;
  font-weight: 500 !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25) !important;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25) !important;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
    0 1px 2px rgba(0, 0, 0, 0.05) !important;
  /* border-width: 1px !important; */
}

.btnSearch:hover,
.btnIcon:hover {
  background-color: #f3f3f3 !important;
  color: #333 !important;
}

.btnCancel,
.btnClear {
  min-width: 6em;

  padding: 5px 20px !important;
  border-radius: 4px !important;
  font-weight: 500 !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25) !important;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25) !important;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
    0 1px 2px rgba(0, 0, 0, 0.05) !important;
  border-width: 1px !important;
  background: linear-gradient(to top, #e9e8ea 0%, #fcfaf9 100%) !important;
  background-repeat: no-repeat !important;
  color: #333 !important;
}

.btnClear {
  height: 40px !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  margin-bottom: 10px;
}

.btnCancel:hover,
.btnClear:hover {
  background: #dddddd !important;
  border: 1px solid #adadad !important;
}

.btnCancel:disabled,
.btnClear:disabled {
  background: #93c3e5 !important;
  border: 1px solid #adadad !important;
}

// #32
.ant-btn.delTimeCheck {
  background: #fafafa;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  margin-left: -3px;
  height: 35px !important;
  min-width: auto;
  color: rgba(0, 0, 0, 0.85);
}

/* Screen */

.cellDisable,
.cellError {
  width: 100%;
  height: 100%;
  background: #eeeeee;
  position: absolute;
  top: 0;
  left: 0;
  // border: 1px solid ;
}

.cellError {
  background: #eeeeee;
}

.ant-picker-dropdown {
  font-size: 20px !important;
  z-index: 10003;
  .ant-picker-time-panel-column {
    overflow-y: scroll;
    width: 100px;
    text-align: center;

    &::-webkit-scrollbar {
      display: none;
    }

    & > li {
      padding: 5px 0;
    }

    & > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
      padding: 0;
    }
  }
}

.tbl-left-createCL {
  .ant-tabs-content-holder {
    background: white;
    overflow-x: auto;
    // overflow-y: hidden;
  }

  .ant-table-body {
    overflow-x: hidden !important;
    overflow-y: auto !important;
  }

  .titleTime {
    border: 1px solid #b3b3b3;
    border-radius: 2px;
    text-align: center;
  }
}

// Override menu
.ant-dropdown-menu {
  padding: 0;

  li.ant-dropdown-menu-item:first-child {
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
  }

  li.ant-dropdown-menu-item:last-child {
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
  }
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: #cbcbcb;
}

// Empty
p.ant-empty-description {
  font-weight: bold;
}

// Override input
.ant-input[disabled] {
  color: #333;
}

// Override input
.ant-picker.black .ant-picker-input > input[disabled] {
  color: #333;
}

/* Override tab */
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-more {
  display: none;
}

.ant-tabs-nav-add {
  white-space: nowrap;
}

.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  margin: 0;
}

.ant-tabs-nav-add {
  font-size: $main_size;
}

.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: 1px solid $color-border;
}

.ant-tabs-content-holder {
  // border: 1px solid $color-border;
  border-top: 0px;
  padding-top: 17px;
}

// .tbl-createCL .ant-tabs-content-holder {
//   background: #fff;
//   background-color: #fff;
// }

/* Border */
.ant-picker,
.ant-input,
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add,
.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-select-multiple .ant-select-selection-item {
  border: 1px solid $color-border;
}

.ant-input-borderless,
.ant-input-borderless:hover,
.ant-input-borderless:focus,
.ant-input-borderless-focused,
.ant-input-borderless-disabled,
.ant-input-borderless[disabled] {
  background-color: transparent;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

textarea:read-only {
  padding: 0;
}

/*  */
.ant-tabs-ink-bar-animated {
  display: none;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 10px;
  // font-size: $main_size;
  /* border: 1px solid #f0f0f0; */
}

.ant-table-thead > tr > th {
  font-size: 14px;
}

.ant-table {
  line-height: 1.1715;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: #f5f5f5;
}

.ant-table-thead > tr > th {
  font-weight: bold;
}

.ant-table-wrapper .ant-table-thead > tr > th,
.ant-table-wrapper .ant-table-tbody > tr > th,
.ant-table-wrapper .ant-table-tbody > tr > td,
.ant-table-wrapper tfoot > tr > th,
.ant-table-wrapper tfoot > tr > td {
  padding: 10px;
}

// .ant-table-wrapper:not(.table-header-auto) .ant-table-thead>tr>th {
//   text-align: center !important;
// }

.big-radio {
  width: 100%;
  text-align: center;

  .text-radio {
    font-size: 20px;
    color: #000;
  }

  .ant-radio {
    font-size: 20px;

    &-inner {
      width: 20px;
      height: 20px;

      &::after {
        width: 12px;
        height: 12px;
      }
    }
  }
}

.info,
.success,
.warning,
.confirm {
  font-size: 18px !important;
}

.info--text {
  color: $second_color;
  padding: 0px 8px;

  &:first-child {
    padding-left: 0;
  }
}

/* Mobile */
.logo-title {
  text-align: center;
  color: white;
  font-weight: 600;
}

.rowNotMargin {
  margin: 0 !important;
  height: auto;
  width: 100%;
}

.text-mobile {
  font-size: 18px !important;
}

// custom input mobile
.inputMobile {
  border: 0px;
  height: 42px !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  width: 100%;
}

.inputSearchMobile > .ant-input-group {
  & > .ant-input-affix-wrapper {
    border: none;
    border-radius: 6px !important;
    font-size: 16px !important;
    background-color: whitesmoke;

    & > .ant-input {
      background-color: whitesmoke;
    }
  }

  & > .ant-input-group-addon {
    padding-left: 10px !important;
  }
}

.inputMobile,
.inputMobile > .ant-input {
  background-color: whitesmoke !important;
}

.inputMobile > .ant-input-suffix > .ant-input-clear-icon {
  font-size: 20px;
}

.input--big__normal.ant-input-affix-wrapper-focused,
.input--big__error.ant-input-affix-wrapper-focused {
  box-shadow: none !important;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  border-color: #e6e6e6 !important;
}

.form-clear {
  line-height: 0;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 0;
  opacity: 0;
  cursor: pointer;
  padding: 16px;
  padding-left: 0px;
  width: 32px;
}

.form-clear-note {
  line-height: 0;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 10px;
  opacity: 0;
  cursor: pointer;
  padding: 10px;
  padding-left: 0px;
  width: 30px;
}

.clearRadio {
  color: #538feb;
  font-size: 16px;
  text-decoration: underline;
}

.ant-switch-checked {
  background-color: $main_color;
}

.form-clear-note:hover,
.form-clear:hover {
  opacity: 100;
}

.input-mobile {
  font-size: 24px !important;
  height: 50px !important;
}

.note-mobile {
  font-size: 24px !important;
}

.input--big {
  border: 1px solid #e6e6e6;
  height: 40px;
  background-color: #fafafa;
  border-radius: 4px !important;
  font-size: 16px;
  font-weight: 600 !important;
  width: 100%;
  color: black;
  padding: 0 36px 0 16px;
  text-align: center;

  &:focus {
    border-color: #ffc982 !important;
    background-color: #fff2e1;
  }

  &:focus ~ .form-clear {
    opacity: 100;
  }

  &__error {
    border: 1px solid #ffa6a6 !important;
    height: 40px;
    font-size: 16px;
    font-weight: 600 !important;
    background-color: #ffeaea;
    text-align: center;
    border-radius: 6px !important;
    width: 100%;
    padding: 0 36px 0 16px;

    &:focus ~ .form-clear {
      opacity: 100;
    }

    &:hover ~ .form-clear {
      opacity: 100;
    }
  }
}

.ant-form-item-has-error .input--big {
  border: 1px solid #ffa6a6 !important;
  height: 40px;
  font-size: 16px;
  font-weight: 600 !important;
  background-color: #ffeaea;
  text-align: center;
  border-radius: 6px !important;
  width: 100%;
  padding: 0 36px 0 16px;

  &:focus ~ .form-clear {
    opacity: 100;
  }

  &:hover ~ .form-clear {
    opacity: 100;
  }
}

.select--big,
.select--big__normal {
  width: 100%;
}

.select-mobile > .ant-select-selector {
  height: 50px !important;
  font-size: 24px !important;

  & > .ant-select-selection-item {
    padding-top: 6px;
  }
}

.select--big__normal > .ant-select-selector {
  border-color: #ffc982 !important;
  background-color: #fff2e1 !important;
  border-radius: 4px !important;
  height: 40px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  box-shadow: none !important;
}

.select--big > .ant-select-selector {
  border-radius: 4px !important;
  height: 40px;
  border-color: #e6e6e6 !important;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  box-shadow: none !important;
  background-color: #fafafa !important;
}

.textNote {
  border: 1px solid #e6e6e6;
  background-color: #fafafa;
  border-radius: 4px;
  padding-right: 22px;
  font-size: 16px;

  &.ant-input:focus,
  &.ant-input:hover {
    box-shadow: none;
    border: 1px solid #e6e6e6;
  }

  &:focus ~ .form-clear-note {
    font-size: 16px !important;
    opacity: 100;
  }
}

// custom card history and bookmark,check result
.cardHistory.am-card {
  background-color: #c3e5f9;
  width: 100%;
  border: 1px solid #82cef9 !important;
  border-left: 4px solid #82cef9 !important;
}

.am-card-footer-content {
  flex: 2 1;
}

// custom any type

.button-plus {
  width: 48px;
  height: 48px;
}

// .textStatus {
//   color: #ffbf6a;

//   &--desktop {
//     font-size: 18px;
//     color: #ffbf6a;
//   }
// }

.buttonAddCheckList {
  border: 0;
  height: 28px;
  border-radius: 5px;
  width: 50px;
  color: white;
  background-color: #4b88e4;

  &:hover,
  :focus {
    background-color: #4b88e4 !important;
    color: white;
  }
}

.buttonAddGroup {
  border: 0;
  height: 28px;
  border-radius: 5px;
  width: 50px;
  background-color: #feb452 !important;

  &:hover,
  :focus {
    background: #feb452 !important;
    color: white !important;
  }
}

.setting > .am-tabs > .am-tabs-tab-bar-wrap {
  padding-right: 100px;
}

.text-weight-600 {
  font-weight: 600;
}

.text-start {
  text-align: flex-start;
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: end;
}

/* custom tab ant mobile */
.am-tabs-default-bar-tab-active {
  background-color: #e7eef8 !important;
  color: #4b88e4 !important;
  border: 1px solid #e7eef8;
  overflow: hidden;
  font-size: 18px;
  font-weight: 600;
}

.am-tabs-default-bar-tab-active:before {
  content: "";
  position: absolute;
  left: 5%;
  bottom: -2px;
  height: 3px;
  width: 90%;
  border-bottom: 6px solid #4b88e4 !important;
  border-radius: 5px;
}

.am-tabs-horizontal .am-tabs-pane-wrap-active {
  overflow-x: hidden;
}

.am-tabs-default-bar-tab {
  line-height: 18px !important;
  overflow: hidden;
  height: 50px;
  text-align: center;
  background-color: #f3f6fa;
  font-size: 18px;
  font-weight: 600;
  color: #a5b0c1;
}

.am-tabs-default-bar-underline {
  width: 0 !important;
  left: 0;
  border: 0;
}

div.ant-typography,
.ant-typography p {
  margin-bottom: 0;
}

/* animation when change page */
#animation {
  animation: fadein 0.8s;
  -moz-animation: fadein 0.8s;
  /* Firefox */
  -webkit-animation: fadein 0.8s;
  /* Safari and Chrome */
  -o-animation: fadein 0.8s;
  /* Opera */
}

.ant-input,
.ant-picker-input input {
  font-size: $main_size;
  border-radius: 4px;
}

.hamburger {
  width: em(24px);
  height: em(16px);
  position: relative;
  padding: 0;
  border: none;
  background-color: $nocolor;
  z-index: 30;
  margin-bottom: 6px;

  &.opened {
    span {
      background-color: $white;

      &:nth-child(1) {
        -webkit-transform: rotate(-45deg) translate(-4px, 6px);
        transform: rotate(-45deg) translate(-4px, 6px);
      }

      &:nth-child(2) {
        opacity: 0;
        transition: 0s;
      }

      &:nth-child(3) {
        width: em(24px);
        top: 16px;
        -webkit-transform: rotate(45deg) translate(-5px, -7px);
        transform: rotate(45deg) translate(-5px, -7px);
      }
    }
  }

  span {
    display: inline-block;
    width: percentage(1);
    height: 2px;
    border-radius: 2px;
    background-color: #fff;
    position: absolute;
    left: 0;

    &:nth-child(1) {
      top: 0;
    }

    &:nth-child(2) {
      top: 7px;
    }

    &:nth-child(3) {
      width: em(18px);
      top: 14px;
    }
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.lds-dual-ring {
  display: inline-block;
  width: 15px;
  height: 15px;
  position: relative;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 15px;
  height: 15px;
  margin: 1px;
  border-radius: 50%;
  border: 2px solid #8692a4;
  border-color: #8692a4 transparent #8692a4 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.header--white .ant-table-thead > tr > th {
  background: transparent !important;
}

.header--white * {
  // border: none  !important;
}

.header--white tr th.ant-table-cell::before {
  display: none !important;
}

.header--white .ant-table-thead tr th.ant-table-cell {
  text-align: flex-start !important;
  padding-top: 5px !important;
}
.gridSearch .ant-table-tbody tr:nth-child(odd) {
  background-color: $bg_machine;
}
.gridSearch .ant-table-tbody tr:nth-child(even) {
  background-color: unset;
}
.gridSearch .ant-table-thead > tr > th {
  height: 45px;
  color: #343a40;
  border-color: $color-border-3;
  border-bottom: 3px solid $color-border-3;
  // border-top: 1px solid $color-border-3;
  background-color: $white;
}
.tableStock .ant-table-thead > tr > th,
.tableStock .ant-table-tbody > tr > td {
  font-size: 21px !important;
}
.modalStock .ant-modal-title {
  font-size: 27px !important;
}

.des-content .ant-collapse {
  border-radius: 8px !important;
}
.ant-input,
.ant-select-selector {
  height: 36px !important;
  // text-align: right;
}

//  .ant-select-arrow{
//   margin-top: -1px !important;
// }

.ant-select-selection-item {
  display: flex;
  align-items: center;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
}
.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: flex-start;
  width: 100%;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  width: 100%;
}
.bg-mainColor {
  background-color: $main_color;
}
.text-mainColor {
  color: $main_color;
}
.border-mainColor {
  border-color: $main_color;
}
.svg-default {
  path {
    fill: $main_color !important;
  }
}
.header-text::after {
  content: "";
  width: 50%;
  height: 3px;
  background-color: $main_color;
  position: absolute;
  bottom: -3px;
  left: 1px;
}
.ant-card-meta {
  margin: -4px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.popover-inner {
  padding: 5px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.ant-modal-confirm-body > .anticon:first-child {
  font-size: 40px;
}

.ant-modal-confirm
  .ant-modal-confirm-body
  > .anticon
  + .ant-modal-confirm-title
  + .ant-modal-confirm-content {
  margin-left: 52px;
}

@media print {
  body * {
    visibility: hidden;
  }
  body {
    margin: 0;
    box-shadow: none;
  }
  #printArea,
  #printArea * {
    visibility: visible;
  }
  #printArea {
    overflow: visible;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    font-size: 11pt;

    table td,
    table th {
      padding: 4px;
    }
  }
}

.wifi-wrapper-default {
  padding-top: 5px;
  padding-right: 10px;
}

.wifi-wrapper {
  padding-top: 0px !important;
  background: white;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
  text-align: center;

  .wifi-img {
    margin: 0 auto;
    vertical-align: middle
  }
}

@media (max-width: 768px) {
  .map-device-setting {
    .ant-modal-content {
      padding-left: 4px;
      padding-right: 4px;
    }
  }
}

.ant-layout-header{
  line-height: 16px !important;
  min-height: 54px;
  height: auto !important;
}