@import "../../styles/css/vars.scss";
.ant-layout-sider,
.ant-menu {
  transition: 0.5s;
  z-index: 1000;
}

.ant-layout .ant-layout-sider-children {
  height: calc(100vh - 300px) !important;
}

@include desktop {
  .ant-menu-root:not(.ant-menu-inline-collapsed) {
    // max-height: calc(100vh - 234px) !important;
    overflow-y: auto;
  }
}
.customSubMenu {
  & > .ant-menu-submenu-title {
    height: 50px !important;
    line-height: 50px !important;
    margin: 0 !important;
    background: #232a3d;
    font-size: 14px;
    font-weight: 500;
    &:hover {
      background-color: $main_color;
    }
  }
  &.ant-menu-submenu-open > .ant-menu-submenu-title,
  &.ant-menu-submenu-selected > .ant-menu-submenu-title {
    color: #fff;
    background-color: $main_color;
  }
  &--mobile {
    & > .ant-menu-submenu-title {
      height: 50px !important;
      line-height: 50px !important;
      margin: 0 !important;
      background: #232a3d;
      font-size: 14px;
      font-weight: 500;
    }
    &.ant-menu-submenu-selected > .ant-menu-submenu-title {
      color: #fff;
      background-color: $main_color;
    }
  }
}
.custom-drawer {
  z-index: 10000;
  .ant-drawer-body {
    padding: 0px;
    background-color: $main_color;
  }
  .ant-drawer-footer {
    border-top-color: $main_color;
    background-color: $main_color;
  }
  &--select {
    z-index: 10001;
    .title {
      font-size: 20px;
      font-weight: 600;
    }
    .row-select {
      padding: 8px 0;
      &:nth-child(even) {
        background: #f7f9fc;
      }
      .icon {
        display: none;
      }
      .name-office {
        font-weight: normal;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      &--selected {
        .name-office {
          max-width: calc(100% - 20px);
          font-weight: bold;
        }
        .icon {
          display: block;
        }
      }
    }
    .ant-drawer-body {
      padding: 8px;
    }
  }
}
hr.menu-bottom-divider {
  border-top: 1px solid #2c344a;
  border-bottom: none;
  border-left: none;
  border-right: none;
  margin-top: 0px;
  margin-bottom: 0px;
  width: calc(100% - 20px);
}
.buttonIcon {
  padding: 0px 10px !important;
  font-size: 18px !important;
  height: auto !important;
  color: $main_color !important;
  width: 100%;
}
.ant-menu-inline-collapsed > .ant-menu-item {
  padding: 0 20px !important;
}

.ant-menu-item {
  border-radius: 0 !important;
}
.ant-menu-vertical .ant-menu-item {
  width: 100% !important;
}
li.ant-menu-item,
li.ant-menu-item.ant-menu-item-selected {
  margin: 0 !important;
}
.iconSideBar {
  height: 20px;
  width: 20px;
}

.copy-right {
  font-size: 10px;
  color: #a0a9b1;
  z-index: -1;
  position: absolute;
  bottom: 48px;
  padding: 13px;
  &__mobile {
    font-size: 10px;
    color: #a0a9b1;
  }
}

.ant-menu .ant-menu-item .ant-menu-item-icon,
.ant-menu .ant-menu-submenu-title .ant-menu-item-icon {
  vertical-align: -0.14em;
}

.ant-menu-vertical .ant-menu-item {
  padding-inline: 25px !important;
}

.ant-menu-item.ant-menu-item-only-child.custom-menu {
  padding-inline: 15px !important;
}

.custom-menu {
  cursor: default !important;
  padding: 0 !important;
  color: #fff !important;
  font-weight: 600 !important;
  height: 30px !important;
  line-height: 30px !important;
  margin-inline: 4px !important;
  margin-block: 4px !important;
  border-radius: 0 !important;
}

.systemName {
  color: #fff;
  font-weight: bold;
  font-size: 30px;
  text-align: center;
}
