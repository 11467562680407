.custom-table .ant-table-cell {
  border: 1px solid lightgray;
  border-right: none;
}

.custom-table .ant-table-cell:last-child {
  border-right: 1px solid lightgray;
}

.custom-table {
  border-bottom: 1px solid lightgray;
}

.vertical-align-top {
  vertical-align: top;
}
.report-cell-date {
  padding: 10px 0px !important;
  width: 3% !important;
}
