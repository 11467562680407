$bg--warning: #f9cc9d;
$bg--warning2: #ffa326;
$bg--login: #1f212e;
$bg--error: #e13c3c;
$bg--info: #1890ff;
$bg--cancel: #f9f9f9;
$bg--success: #61bc48;
$bg--confirm: #ffbf6a;
.button {
  height: 40px;
  width: 100%;
  border: 0;
  border-radius: 5px;
  color: whitesmoke;
  &--big {
    height: 60px;
    font-size: 20px;
    font-weight: 500;
  }
  &--cancel {
    background-color: $bg--cancel;
    border: 1px solid #e4e4e4;
    color: #717171;
    &.ant-btn:focus,
    &.ant-btn:hover {
      background-color: $bg--cancel !important;
      color: #717171;
      border: 1px solid #e4e4e4;
    }
  }
  &--warning {
    background-color: $bg--warning;
    &.ant-btn:focus {
      background-color: $bg--warning !important;
      color: white !important;
    }
    &:hover,
    :focus {
      background-color: $bg--warning !important;
      color: white !important;
    }
  }
  &--warning2 {
    background-color: $bg--warning2;
    &.ant-btn:focus {
      background-color: $bg--warning2 !important;
      color: white !important;
    }
    &:hover,
    :focus {
      background-color: $bg--warning2 !important;
      color: white !important;
    }
  }
  &--confirm {
    background-color: $bg--confirm;
    &.ant-btn:focus {
      background-color: $bg--confirm !important;
      color: white !important;
    }
    &:hover,
    :focus {
      background-color: $bg--confirm !important;
      color: white !important;
    }
  }
  &--success {
    background-color: $bg--success;
    &.ant-btn:focus {
      background-color: $bg--success !important;
      color: white !important;
    }
    &:hover,
    :focus {
      background-color: $bg--success !important;
      color: white !important;
    }
  }
  &--login {
    background-color: $bg--login;
    &.ant-btn:focus {
      background-color: $bg--login !important;
      color: white !important;
    }
    &:hover,
    :focus {
      background-color: $bg--login !important;
      color: white !important;
    }
  }

  &--error {
    background-color: $bg--error;
    &.ant-btn:focus {
      background-color: $bg--error !important;
      color: white !important;
    }
    &:hover,
    :focus {
      background-color: $bg--error !important;
      color: white !important;
    }
  }

  &--info {
    background-color: $bg--info;
    &.ant-btn:focus {
      background-color: $bg--info !important;
      color: white !important;
    }
    &:hover,
    :focus {
      background-color: $bg--info !important;
      color: white !important;
    }
  }
}
@import "./mixin.scss";
@import "./vars.scss";
