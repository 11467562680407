.title-login {
  margin-bottom: 8px;
  font-weight: 500;
  padding: 0 !important;
}


.alert {
  position: relative;
  padding: 14px 10px;
  margin-bottom: 1rem;
  border-radius: 5px;
  color: #fff;
}

.notiRequied {
  color: #dd4b39;
  display: inline-block;
  font-size: 14px;
  margin: 5px 0px;
  font-weight: bold;

  &__mark {
    padding: 2px;
    display: inline-block;
    background-color: #dd4b39;
    border-radius: 0.25rem;
    color: #fff;
    font-weight: normal;
    font-size: 10px;
  }
}
